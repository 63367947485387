.TitlePage {
	margin-top: 20px;
	margin-bottom: 40px;
	& &-title {
		.ant-typography {
			margin-bottom: 0;
		}
	}

	& &-button {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	& &-CSV {
		margin-left: 10px;
	}
}
