%general {
	width: 100%;
}

%DragAndDrop {
	padding: 9px 14px;
	border: 1px solid #f2f2f2;
}

.CreateCourses {
	padding-bottom: 60px;
	&__title {
		margin-top: 20px;
		h1.ant-typography {
			margin-bottom: 0;
		}
		& &-block {
			@media (min-width: 768px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		&-button {
			@media (min-width: 768px) {
				text-align: right;
			}
		}
	}

	&__switch {
		h3.ant-typography {
			visibility: hidden;
		}
		& &-switch {
			margin-left: 9px;
			transform: scale(1.2);
		}
	}

	&__team {
		.ant-select {
			@extend %general;
		}
	}

	&__related_courses {
		.ant-select {
			@extend %general;
		}
	}

	& &__numbers {
		margin-bottom: 60px;
		&-title {
			button {
				margin-bottom: 5px;
			}
		}
		.dropItem {
			.drag {
				@extend %DragAndDrop;
				.ant-input-number {
					width: 100%;
				}
				.ant-space-item {
					margin-bottom: 10px;
				}
			}
		}
	}

	& &__prices {
		margin-bottom: 60px;
		&-title {
			display: flex;
			flex-direction: column;

			button {
				margin-bottom: 5px;
				display: inline-block;
				width: fit-content;
			}
		}
		.dropItem {
			.drag {
				@extend %DragAndDrop;
				.ant-input-number {
					width: 100%;
				}
				.ant-space-item {
					margin-bottom: 10px;
				}
			}
		}
		&-csvLink {
			display: inline-block;
			margin-bottom: 15px;
		}
	}

	.ant-space {
		@extend %general;
	}

	.ant-select {
		@extend %general;
	}

	&__Tab {
		&-One {
			.ant-row {
				margin-bottom: 30px;
			}
		}
	}
}

.tableCountItems {
	margin-top: 15px;
}

.ant-upload-list-picture-card .ant-upload-draggable-list-item {
	border-left: 2px dashed rgba(0, 0, 0, 0);
	border-right: 2px dashed rgba(0, 0, 0, 0);
}

.ant-upload-list-picture-card .ant-upload-draggable-list-item.drop-over-downward {
	border-right-color: #1890ff;
}

.ant-upload-list-picture-card .ant-upload-draggable-list-item.drop-over-upward {
	border-left-color: #1890ff;
}

.ant-upload-list-picture-card .ant-upload-draggable-list-item {
	width: 100%;
	height: 100%;
}
