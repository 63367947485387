%general {
	width: 100%;
}

.CreateCategories {
	padding-bottom: 60px;
	&__title {
		margin-top: 20px;
		h1.ant-typography {
			margin-bottom: 0;
		}
		& &-block {
			@media (min-width: 768px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		&-button {
			@media (min-width: 768px) {
				text-align: right;
			}
		}
	}

	.ant-space {
		@extend %general;
	}

	.ant-select {
		@extend %general;
	}
}
