.CreateLesson {
	.ant-space {
		width: 100%;
		.ant-space-item .CreateLesson__group {
			margin-bottom: 15px;
		}
	}
	&__submit {
		margin-top: 15px;
		text-align: right;
	}
}
