@import "~antd/dist/antd.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

.container-large {
	max-width: 2000px;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;
}

.container {
	max-width: 1800px;
	margin: 0 auto;
	width: 100%;
	padding: 0px 15px;
}

.tab-block .ant-tabs-content-holder .ant-tabs-tabpane>.ant-row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.ant-pagination-options {
	display: none !important;
}

.tableAllItems {
	width: 130px;
	margin-top: -48px;
}

.editor-block {
	min-height: 150px;
	padding: 6.5px 11px;
	border: 1px solid #d9d9d9;

	.public-DraftStyleDefault-block {
		margin: 0;
	}
}

// Размеры
// @media (max-width: 575px) {}

// @media (min-width: 576px) {}

// @media (min-width: 768px) {}

// @media (min-width: 992px) {}

// @media (min-width: 1200px) {}

// @media (min-width: 1600px) {}

// * {
// 	border: 1px solid #f2f2f2;
// }