.form {
	display: flex;
	align-items: center;

	&Input {
		width: 100%;
	}

	& &Select {
		max-width: 60px;
	}
}