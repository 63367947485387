%general {
	width: 100%;
}

.CreateUsers {
	padding-bottom: 60px;
	&__title {
		margin-top: 20px;
		h1.ant-typography {
			margin-bottom: 0;
		}
		& &-block {
			@media (min-width: 768px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		&-button {
			@media (min-width: 768px) {
				text-align: right;
			}
		}
	}

	&__content {
		.ant-row {
			margin-bottom: 20px;
		}
	}

	.ant-space {
		@extend %general;
	}

	.ant-select {
		@extend %general;
	}

	&__row {
		.ant-select,
		.ant-input-number {
			width: 100%;
		}
	}
}

.user-payments {
	padding-bottom: 30px;
	&__buttons {
		display: flex;
		justify-content: flex-end;
	}
}