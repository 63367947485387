.header-layout {
  .logo {
    padding: 10px 0px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
      max-height: 36px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .ant-layout {
    padding: 0 15px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .ant-menu {
      background-color: #f0f2f5;
    }
  }
}
