.authPage {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;
	.textDivider {
		padding-top: 10px;
		color: #797979;
	}

	&__container {
		.ant-space {
			width: 100%;
			&-item:last-child {
				margin-top: 25px;
			}
		}
	}

	&__img {
		max-width: 290px;
		width: 100%;
		margin-bottom: 30px;
		img {
			object-fit: contain;

			width: 100%;
			height: 100%;
		}
	}
}
